(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-columns/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-columns/assets/javascripts/render-component.js');

'use strict';

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _asyncIterator(r) { var n, t, o, e = 2; for ("undefined" != typeof Symbol && (t = Symbol.asyncIterator, o = Symbol.iterator); e--;) { if (t && null != (n = r[t])) return n.call(r); if (o && null != (n = r[o])) return new AsyncFromSyncIterator(n.call(r)); t = "@@asyncIterator", o = "@@iterator"; } throw new TypeError("Object is not async iterable"); }
function AsyncFromSyncIterator(r) { function AsyncFromSyncIteratorContinuation(r) { if (Object(r) !== r) return Promise.reject(new TypeError(r + " is not an object.")); var n = r.done; return Promise.resolve(r.value).then(function (r) { return { value: r, done: n }; }); } return AsyncFromSyncIterator = function (r) { this.s = r, this.n = r.next; }, AsyncFromSyncIterator.prototype = { s: null, n: null, next: function () { return AsyncFromSyncIteratorContinuation(this.n.apply(this.s, arguments)); }, return: function (r) { var n = this.s.return; return void 0 === n ? Promise.resolve({ value: r, done: !0 }) : AsyncFromSyncIteratorContinuation(n.apply(this.s, arguments)); }, throw: function (r) { var n = this.s.return; return void 0 === n ? Promise.reject(r) : AsyncFromSyncIteratorContinuation(n.apply(this.s, arguments)); } }, new AsyncFromSyncIterator(r); }
const isServer = typeof exports === 'object';
let logger;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('banner-groups: banner-columns');
} else {
  logger = svs.core.log.getLogger('banner-groups: banner-columns');
}

async function renderComponent(data, widgetInfo, req, res) {
  let hbsTemplateCompiled;
  let columnsCount;
  if (isServer) {
    const hbs = global.internalInstances.get('hbs');
    hbsTemplateCompiled = hbs.cache['components-banner_groups-banner_columns-banner_columns'];
  } else {
    hbsTemplateCompiled = svs.banner_groups.banner_columns.templates.banner_columns;
  }
  let i = 0;
  const childElements = [];
  let columnWrapper = 'col-xs-12';
  let columnClasses;
  let fillOddRows;
  let columns;
  const gridClasses = 'banner-group-columns';
  var _iteratorAbruptCompletion = false;
  var _didIteratorError = false;
  var _iteratorError;
  try {
    for (var _iterator = _asyncIterator(data.banners), _step; _iteratorAbruptCompletion = !(_step = await _iterator.next()).done; _iteratorAbruptCompletion = false) {
      const banner = _step.value;
      {
        if (banner.layoutType) {
          let bannerComponent;
          fillOddRows = banner.layoutConfig.fillOddRows;
          columns = banner.layoutConfig.allowedColumns[0];
          if (isServer) {
            bannerComponent = data.components["banner_layouts/".concat(banner.layoutType)];
          } else {
            bannerComponent = svs.banner_layouts[banner.layoutType.replace(/-/g, '_')];
          }
          if (!fillOddRows) {
            if (columns && columns > 1 && data.banners.length <= 1 || columns && columns > 1 && widgetInfo.count === 1) {
              return;
            }
          }
          try {
            const bannerHtml = await bannerComponent.renderComponent(banner, widgetInfo, i + 1, req, res);
            if (columns) {
              columnsCount = 12 / columns;
              columnWrapper = "banner-column-wrapper col-xs-12 col-am-".concat(columnsCount);
            }
            childElements.push({
              html: bannerHtml,
              layoutType: banner.layoutType,
              columnWrapper,
              columnClasses
            });
          } catch (error) {
            logger.warn("Banner skipped due to banner loading error: ".concat(error.toString()), req);
          }
        } else {
          logger.warn("Banner id ".concat(banner.id, " lacks layoutType property"));
        }
        i++;
      }
    }
  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (_iteratorAbruptCompletion && _iterator.return != null) {
        await _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }
  const childElementsCount = childElements.length % columns;
  const allItemsAreColumnArticles = childElements.filter(child => child.layoutType === 'column-article').length === childElements.length;
  if (!allItemsAreColumnArticles && fillOddRows && childElements.length % 2 !== 0) {
    const lastChild = childElements[childElements.length - 1];
    lastChild.columnWrapper = 'banner-column-full-wrapper col-xs-12 col-sm-12';
  }
  let reducedChildElements = !fillOddRows && childElementsCount > 0 ? childElements.slice(0, -childElementsCount) : childElements;
  if (allItemsAreColumnArticles) {
    reducedChildElements = reducedChildElements.map(child => _objectSpread(_objectSpread({}, child), {}, {
      columnWrapper: 'banner-column-wrapper'
    }));
  }
  const model = {
    title: data.config.title,
    widgetId: data.config.widgetId,
    subTitle: data.config.subTitle,
    childElements: reducedChildElements,
    gridClasses,
    childElementsCount,
    isArticles: allItemsAreColumnArticles
  };
  return hbsTemplateCompiled(model) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  svs.banner_groups.banner_columns = {
    renderComponent
  };
}

 })(window);