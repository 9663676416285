(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-columns/assets/javascripts/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-columns/assets/javascripts/handlebars-helpers.js');


'use strict';

(function () {
  if (svs.isServer) {
    const hbs = require('hbs');
    const urlMapping = require('trinidad-core').core.urlMapping;
    registerHelpers(hbs, urlMapping);
  } else {
    registerHelpers(Handlebars, svs.core.urlMapping);
  }
  function registerHelpers(hbs) {
    hbs.registerHelper('modulo', options => {
      const index = options.data.index;
      const value = options.hash.value;
      if (index % value === 0) {
        return options.fn(this);
      }
      return options.inverse(this);
    });
  }
})();

 })(window);