(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_groups/banner-columns/views/banner-columns.js') >= 0) return;  svs.modules.push('/components/banner_groups/banner-columns/views/banner-columns.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_groups=_svs.banner_groups||{};
_svs.banner_groups.banner_columns=_svs.banner_groups.banner_columns||{};
_svs.banner_groups.banner_columns.templates=_svs.banner_groups.banner_columns.templates||{};
svs.banner_groups.banner_columns.templates.banner_columns = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"gridClasses") || (depth0 != null ? lookupProperty(depth0,"gridClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"gridClasses","hash":{},"data":data,"loc":{"start":{"line":1,"column":31},"end":{"line":1,"column":48}}}) : helper))) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"banner-columns-row banner-columns-article-row grid-row column-flex\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"childElements") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":10,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"columnWrapper") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":65}}})) != null ? stack1 : "")
    + " banner-columns-article'>\n        <div class='banner-column-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"columnClasses") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":39},"end":{"line":6,"column":86}}})) != null ? stack1 : "")
    + "'>\n          "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"html") || (depth0 != null ? lookupProperty(depth0,"html") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"html","hash":{},"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":7,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"columnWrapper") || (depth0 != null ? lookupProperty(depth0,"columnWrapper") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"columnWrapper","hash":{},"data":data,"loc":{"start":{"line":5,"column":39},"end":{"line":5,"column":58}}}) : helper))) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"columnClasses") || (depth0 != null ? lookupProperty(depth0,"columnClasses") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"columnClasses","hash":{},"data":data,"loc":{"start":{"line":6,"column":60},"end":{"line":6,"column":79}}}) : helper))) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"childElements") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"isEven")||(depth0 && lookupProperty(depth0,"isEven"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"isEven","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":28}}}),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":14,"column":99}}})) != null ? stack1 : "")
    + "\n      <div class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"columnWrapper") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":18},"end":{"line":15,"column":65}}})) != null ? stack1 : "")
    + "'>\n        <div class='banner-column-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"columnClasses") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":86}}})) != null ? stack1 : "")
    + "'>\n          "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"html") || (depth0 != null ? lookupProperty(depth0,"html") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"html","hash":{},"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":17,"column":20}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n      </div>\n      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(lookupProperty(helpers,"isEven")||(depth0 && lookupProperty(depth0,"isEven"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"isEven","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":27}}}),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":6},"end":{"line":20,"column":43}}})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,(data && lookupProperty(data,"last")),"&&",(lookupProperty(helpers,"isOdd")||(depth0 && lookupProperty(depth0,"isOdd"))||alias2).call(alias1,(data && lookupProperty(data,"index")),{"name":"isOdd","hash":{},"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":21,"column":42}}}),{"name":"compare","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":6},"end":{"line":21,"column":64}}})) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return " ";
},"13":function(container,depth0,helpers,partials,data) {
    return "<div class=\"banner-columns-row grid-row column-flex\">";
},"15":function(container,depth0,helpers,partials,data) {
    return " </div>";
},"17":function(container,depth0,helpers,partials,data) {
    return " </div> ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class='"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"gridClasses") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":55}}})) != null ? stack1 : "")
    + " padding-xs-h-1 padding-md-custom gutter-am-2'>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isArticles") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":23,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});
Handlebars.partials['components-banner_groups-banner_columns-banner_columns'] = svs.banner_groups.banner_columns.templates.banner_columns;
})(svs, Handlebars);


 })(window);